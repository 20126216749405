import {
  forum,
  partner,
  country,
  community,
  userType,
  expert,
  adminUser,
  subspeciality,
  stateUrl,
  cityUrl,
} from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      forum_members: [],
      forum_id: null,
      pageNo: null,
      status: false,
      table_header: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "thumbnail_image",
          label: "Thumbnail",
        },
        {
          key: "title",
          label: "Forum Name",
        },
        {
          key: "partner_id",
          label: "Partner Name",
        },
        {
          key: "link_name",
          label: "Forum Link Name",
        },
        {
          key: "forum_visibility",
          label: "Visibility",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      oldForm: {},
      form: {
        name: "",
        link_name: "",
        forum_type: "",
        partner_id: "",
        forum_visibility: "",
        geographic_type: "country",
        community: [],
        user_types: [],
        brand_page_link_text: "",
        brand_page_link: "",
        pre_login_description: "",
        description: "",
        html_content: "",
        is_open_forum: 0,
        is_brand: 0,
        text_color: "#00000",
        bg_color: "#00000",
        bg_light_color: "#00000",
        show_followers: 0,
        is_knowledge_academy_active: 0,
        council_experts: [],
        other_experts: [],
        sub_specialities: [],
        forum_manager: [],
        country: [],
        state: [],
        city: [],
        knowledge_academy_name: "",
        knowledge_academy_header_text: "",
        interested_in_grant_text: "",
        reaching_out_text: "",
        knowledge_academy_to_address: "",
        external_link: "",
        privacy_rule: [],
        rule_type_password: "",
        forum_other_expert_text: "Others",
        forum_council_expert_text: "Council",
        knowledge_academy_thank_you_message: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        forum_contribution: [
          {
            forum_contribution_title: "",
            member_type: [],
          },
        ],
        is_homepage_custom: 0,
      },
      website_banner_image: "",
      pre_login_image: "",
      pre_login_image2: "",
      thumbnail_image: "",
      thumbnail_image_logo: "",
      image_name: "",
      knowledge_academy_banner_image: "",
      knowledge_academy_banner_image_mobile: "",
      invitation_file: "",
      thumbnail_image_url: "",
      thumbnail_image_logo_url: "",
      image_name_url: "",
      website_banner_image_url: "",
      pre_login_image_url: "",
      pre_login_image2_url: "",
      invitation_file_url: null,
      knowledge_academy_banner_image_url: "",
      knowledge_academy_banner_image_mobile_url: "",
      edit: {
        website_banner_image_url: "",
        pre_login_image_url: "",
        pre_login_image2_url: "",
        thumbnail_image_url: "",
        thumbnail_image_logo_url: "",
        image_name_url: "",
        knowledge_academy_banner_image_url: "",
        knowledge_academy_banner_image_mobile_url: "",
        invitation_file_url: null,
      },
      community: [],
      partner: [],
      user_types: [],
      forum_expert: [],
      forum_subspeciality: [],
      discussion_forum_manager: [],
      forum_geographic: [
        {
          text: "Country",
          value: "country",
        },
        {
          text: "State",
          value: "state",
        },
        {
          text: "City",
          value: "city",
        },
      ],
      discussion_forum_type: [
        {
          text: "College",
          value: "college",
        },
        {
          text: "Disease",
          value: "disease",
        },
        {
          text: "Partner",
          value: "partner",
        },
        {
          text: "Associate",
          value: "associate",
        },
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor",
          value: "doctor",
        },
      ],
      discussion_forum_visibility: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Private",
          value: "private",
        },
      ],
      forum_privacy_rules: [
        {
          text: "Invitation",
          value: "invitation",
        },
        {
          text: "Password",
          value: "password",
        },
        {
          text: "Approval",
          value: "approval",
        },
      ],
      params: "",
      tableData: [],
      activeTab: "all",
      key: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      isKnowledgeAcademy: false,
      allCountry: [],
      allState: [],
      allCity: [],
      cta_data: {
        sample: {
          label: "Sample",
          text: "",
          link: "",
          active: false,
        },
        request_expert: {
          label: "Request Expert",
          text: "",
          link: "",
          active: false,
        },
        visit_to_page: {
          label: "Visit to Page",
          text: "",
          link: "",
          active: false,
        },
        visit_brand_page: {
          label: "Visit brand page",
          text: "",
          link: "",
          active: false,
        },
      },
      forum_tabs: {
        overview: {
          index: 1,
          label: "Overview",
          active: false,
        },
        videos: {
          index: 2,
          label: "Videos",
          active: false,
        },
        reading_material: {
          index: 3,
          label: "Reading Material",
          active: false,
        },
        discussion_forum: {
          index: 4,
          label: "Discussion Forum",
          active: false,
        },
        cases: {
          index: 5,
          label: "Cases",
          active: false,
        },
        live_events: {
          index: 6,
          label: "Live Events",
          active: false,
        },
        podcasts: {
          index: 7,
          label: "Podcast",
          active: false,
        },
        askanexpert: {
          index: 8,
          label: "Ask an expert",
          active: false,
        },
        brand_material: {
          index: 9,
          label: "Brand Material",
          active: false,
        },
      },
      contribution_type: [],
      contribution_member: [],
    };
  },
  methods: {
    changeCustomForum() {
      this.form.html_content = "";
      if (this.form.is_homepage_custom) {
        const aboutUsTab = {
          index: 1,
          label: "About Us",
          active: false,
        };
        for (const tabKey in this.forum_tabs) {
          const tab = this.forum_tabs[tabKey];
          tab.index++;
        }
        this.$set(this.forum_tabs, "aboutus", aboutUsTab);
      } else {
        this.$delete(this.forum_tabs, "aboutus");
        for (const tabKey in this.forum_tabs) {
          const tab = this.forum_tabs[tabKey];
          tab.index--;
        }
      }
    },
    addContribution() {
      this.form.forum_contribution.push({
        forum_contribution_title: "",
        member_type: [],
      });
    },
    removeContribution(key) {
      if (key == 0) {
        return;
      } else {
        this.form.forum_contribution.splice(key, 1);
      }
    },
    async fetchForumContribution() {
      try {
        const url = forum.forumContribution;
        const data = await this.getRequest(url);
        if (data.status) {
          this.contribution_type = data.response;
        }
      } catch (err) {
        console.log(err);
      }
    },
    fetchForumContributionMember: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.contribution_member = [];
      if (query != null && query != "") {
        this.$store.commit("loader/updateStatus", true);
        const url = forum.forumContributorMember + "?search=" + query;
        const data = await this.getRequest(url);
        if (data.status) {
          this.$store.commit("loader/updateStatus", false);
          this.contribution_member = data.response.data;
        }
      }
    }, 1000),
    changeGeographicType() {
      (this.form.city = null),
        (this.form.state = null),
        (this.form.country = null);
    },
    resetPrivacyRule() {
      this.form.privacy_rule = [];
    },
    async getStatesByCountry() {
      this.form.state = [];
      this.form.city = [];
      if (this.form.country != null) {
        const url = stateUrl.state + "/" + this.form.country;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allState = data.response;
        }
      }
    },
    async getCitiesByState() {
      this.form.city = [];
      if (this.form.state != null) {
        const url =
          cityUrl.city + "/" + this.form.country + "/" + this.form.state;
        const data = await this.getRequest(url);
        if (data.status) {
          this.allCity = data.response;
        }
      }
    },
    slugify() {
      this.forum_name = this.form.name;
      if (this.forum_name) {
        const slug_generate = this.forum_name
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-")
          .replace(/^-+/, "");
        this.form.link_name = slug_generate;
      } else {
        this.form.link_name = null;
      }
    },
    changeForumType(val) {
      if (val == "partner") {
        if (this.partner.length <= 0) {
          this.fetchPartner();
        }
      }
    },

    changeKnowledgeAcademy(val) {
      if (val) {
        this.isKnowledgeAcademy = true;
      } else {
        this.isKnowledgeAcademy = false;
        this.form.knowledge_academy_name = "";
        this.form.knowledge_academy_header_text = "";
        this.form.interested_in_grant_text = "";
        this.form.reaching_out_text = "";
        this.form.knowledge_academy_to_address = "";
        (this.form.external_link = ""),
          (this.knowledge_academy_banner_image = ""),
          (this.knowledge_academy_banner_image_mobile = "");
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } else {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = forum.forumUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = forum.forumUrl + "?filter=" + txt;
        }
      } else if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = forum.forumUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = forum.forumUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = forum.forumUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = forum.forumUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.getUrl();
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    getLink(linkName) {
      window.open(this.$hostLink + "/forums/" + linkName);
    },
    readFile(e, txt) {
      if (txt == "knowledge_academy_banner_image") {
        this.knowledge_academy_banner_image = e.target.files[0];
        if (this.knowledge_academy_banner_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.knowledge_academy_banner_image.reset();
          this.knowledge_academy_banner_image_url = "";
          this.edit.knowledge_academy_banner_image_url = "";
          this.knowledge_academy_banner_image = "";
          return false;
        } else {
          this.knowledge_academy_banner_image_url = URL.createObjectURL(
            this.knowledge_academy_banner_image
          );
          this.edit.knowledge_academy_banner_image_url = "";
        }
        return true;
      } else if (txt == "knowledge_academy_banner_image_mobile") {
        this.knowledge_academy_banner_image_mobile = e.target.files[0];
        if (this.knowledge_academy_banner_image_mobile.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.knowledge_academy_banner_image_mobile.reset();
          this.knowledge_academy_banner_image_mobile_url = "";
          this.edit.knowledge_academy_banner_image_mobile_url = "";
          this.knowledge_academy_banner_image_mobile = "";
          return false;
        } else {
          this.knowledge_academy_banner_image_mobile_url = URL.createObjectURL(
            this.knowledge_academy_banner_image_mobile
          );
          this.edit.knowledge_academy_banner_image_mobile_url = "";
        }
        return true;
      } else if (txt == "thumbnail_image_logo") {
        this.thumbnail_image_logo = e.target.files[0];
        if (this.thumbnail_image_logo.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.thumbnail_image_logo.reset();
          this.thumbnail_image_logo_url = "";
          this.thumbnail_image_logo = "";
          this.edit.thumbnail_image_logo_url = "";
          return false;
        } else {
          this.thumbnail_image_logo_url = URL.createObjectURL(
            this.thumbnail_image_logo
          );
          this.edit.thumbnail_image_logo_url = "";
        }
        return true;
      } else if (txt == "website_banner_image") {
        this.website_banner_image = e.target.files[0];
        if (this.website_banner_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.website_banner_image.reset();
          this.website_banner_image_url = "";
          this.website_banner_image = "";
          this.edit.website_banner_image_url = "";
          return false;
        } else {
          this.website_banner_image_url = URL.createObjectURL(
            this.website_banner_image
          );
          this.edit.website_banner_image_url = "";
        }
        return true;
      } else if (txt == "pre_login_image") {
        this.pre_login_image = e.target.files[0];
        if (this.pre_login_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.pre_login_image.reset();
          this.pre_login_image_url = "";
          this.pre_login_image = "";
          this.edit.pre_login_image_url = "";
          return false;
        } else {
          this.pre_login_image_url = URL.createObjectURL(this.pre_login_image);
          this.edit.pre_login_image_url = "";
        }
        return true;
      } else if (txt == "pre_login_image2") {
        this.pre_login_image2 = e.target.files[0];
        if (this.pre_login_image2.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.pre_login_image2.reset();
          this.pre_login_image2_url = "";
          this.pre_login_image2 = "";
          this.edit.pre_login_image2_url = "";
          return false;
        } else {
          this.pre_login_image2_url = URL.createObjectURL(
            this.pre_login_image2
          );
          this.edit.pre_login_image2_url = "";
        }
        return true;
      } else if (txt == "thumbnail_image") {
        this.thumbnail_image = e.target.files[0];
        if (!this.thumbnail_image) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "No File Choosen",
          });
          return false;
        }
        if (this.thumbnail_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.thumbnail_image.reset();
          this.edit.thumbnail_image_url = "";
          this.thumbnail_image = "";
          this.thumbnail_image_url = "";
          return false;
        } else {
          this.thumbnail_image_url = URL.createObjectURL(this.thumbnail_image);
          this.edit.thumbnail_image_url = "";
        }
        return true;
      } else if (txt == "image_name") {
        this.image_name = e.target.files[0];
        if (this.image_name.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image_name.reset();
          this.image_name_url = "";
          this.image_name = "";
          this.edit.image_name_url = "";
          return false;
        } else {
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit.image_name_url = "";
        }
        return true;
      } else if (txt == "invitation_file") {
        this.invitation_file = e.target.files[0];
        if (this.invitation_file.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.invitation_file.reset();
          this.invitation_file = "";
          this.invitation_file_url = "";
          this.edit.invitation_file_url = "";
          return false;
        } else {
          this.invitation_file_url = URL.createObjectURL(this.invitation_file);
          this.edit.invitation_file_url = "";
        }
        return true;
      }
    },
    async fetchForum(id) {
      this.$store.commit("loader/updateStatus", true);
      let url = forum.forumUrl + "/" + id;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;

        this.forum_id = responseData.id;

        if (responseData.forum_type != "" && responseData.forum_type != null) {
          this.form.forum_type = responseData.forum_type;
        }

        if (
          responseData.forum_visibility != "" &&
          responseData.forum_visibility != null
        ) {
          this.form.forum_visibility = responseData.forum_visibility;
        }

        if (responseData.title != "" && responseData.title != null) {
          this.form.name = responseData.title;
        }

        if (responseData.link_name != "" && responseData != null) {
          this.form.link_name = responseData.link_name;
        }

        if (responseData.partner_id != null && responseData.partner_id != "") {
          this.form.partner_id = responseData.partner_id;
        }

        if (
          responseData.geographic_type != "" &&
          responseData.geographic_type != null
        ) {
          this.form.geographic_type = responseData.geographic_type;
        }

        if (
          responseData.pre_login_description != "" &&
          responseData.pre_login_description != null
        ) {
          this.form.pre_login_description = responseData.pre_login_description;
        }

        if (
          responseData.brand_page_link_text != "" &&
          responseData.brand_page_link_text != null
        ) {
          this.form.brand_page_link_text = responseData.brand_page_link_text;
        }

        if (
          responseData.brand_page_link != "" &&
          responseData.brand_page_link != null
        ) {
          this.form.brand_page_link = responseData.brand_page_link;
        }

        if (
          responseData.description != "" &&
          responseData.description != null
        ) {
          this.form.description = responseData.description;
        }

        if (
          responseData.forum_council_expert_text != "" &&
          responseData.forum_council_expert_text != null
        ) {
          this.form.forum_council_expert_text =
            responseData.forum_council_expert_text;
        }

        if (
          responseData.forum_other_expert_text != "" &&
          responseData.forum_other_expert_text != null
        ) {
          this.form.forum_other_expert_text =
            responseData.forum_other_expert_text;
        }

        if (
          responseData.knowledge_academy_thank_you_message != "" &&
          responseData.knowledge_academy_thank_you_message != null
        ) {
          this.form.knowledge_academy_thank_you_message =
            responseData.knowledge_academy_thank_you_message;
        }

        if (responseData.country != null && responseData.country != "") {
          const country_parse = JSON.parse(responseData.country);
          if (this.form.geographic_type == "country") {
            this.form.country = country_parse.map((item) => {
              return {
                name: item,
              };
            });
          } else {
            country_parse.map((item) => {
              this.form.country = item;
            });
            this.getStatesByCountry();
          }
        }

        if (responseData.state != null && responseData.state != "") {
          const state_parse = JSON.parse(responseData.state);
          if (this.form.geographic_type == "state") {
            this.form.state = state_parse.map((item) => {
              return {
                state: item,
              };
            });
          } else {
            state_parse.map((item) => {
              this.form.state = item;
            });
            this.getCitiesByState();
          }
        }

        if (responseData.city != null && responseData.city != "") {
          const city_parse = JSON.parse(responseData.city);
          this.form.city = city_parse.map((item) => {
            return {
              city: item,
            };
          });
        }

        if (responseData.user_types != null && responseData.user_types != "") {
          const user_type_parse = JSON.parse(responseData.user_types);
          this.form.user_types = this.user_types.filter((user_type) => {
            if (user_type_parse.includes(user_type.value)) {
              return user_type;
            }
          });
        }

        if (
          responseData.forum_manager != null &&
          responseData.forum_manager != ""
        ) {
          const forum_manager_parse = JSON.parse(responseData.forum_manager);
          this.form.forum_manager = this.discussion_forum_manager.filter(
            (forum_manager) => {
              if (forum_manager_parse.includes(forum_manager.id)) {
                return forum_manager;
              }
            }
          );
        }

        if (responseData.community != null && responseData.community != "") {
          this.form.community = responseData.community.map((item) => {
            return item;
          });
        }

        if (
          responseData.sub_specialities != null &&
          responseData.sub_specialities != ""
        ) {
          this.form.sub_specialities = responseData.sub_specialities.map(
            (item) => {
              return item;
            }
          );
        }

        if (responseData.is_open_forum) {
          this.form.is_open_forum = true;
        }

        if (responseData.is_homepage_custom) {
          this.form.is_homepage_custom = true;
        }

        if (responseData.show_followers) {
          this.form.show_followers = true;
        }

        if (responseData.is_brand) {
          this.form.is_brand = true;
        }

        if (responseData.is_knowledge_academy_active) {
          this.form.is_knowledge_academy_active = true;
        }

        if (
          responseData.html_content != "" &&
          responseData.html_content != null
        ) {
          this.form.html_content = responseData.html_content;
        }

        if (
          responseData.council_experts != null &&
          responseData.council_experts != ""
        ) {
          responseData.council_experts.map((item) => {
            this.fetchSingleCouncilExpert(item);
          });
        }

        if (
          responseData.other_experts != null &&
          responseData.other_experts != ""
        ) {
          responseData.other_experts.map((item) => {
            this.fetchSingleOtherExpert(item);
          });
        }

        const privacy_rules = responseData.privacy_rules;
        if (privacy_rules) {
          this.form.privacy_rule = privacy_rules.map((rule) => {
            if (rule.rule_type == "password") {
              this.form.rule_type_password = rule.rule_action;
            }
            return rule.rule_type;
          });
        }

        if (
          responseData.knowledge_academy_name != "" &&
          responseData.knowledge_academy_name != null
        ) {
          this.form.knowledge_academy_name =
            responseData.knowledge_academy_name;
        }

        if (
          responseData.knowledge_academy_header_text != "" &&
          responseData.knowledge_academy_header_text != null
        ) {
          this.form.knowledge_academy_header_text =
            responseData.knowledge_academy_header_text;
        }

        if (
          responseData.knowledge_academy_to_address != "" &&
          responseData.knowledge_academy_to_address != null
        ) {
          this.form.knowledge_academy_to_address =
            responseData.knowledge_academy_to_address;
        }

        if (
          responseData.interested_in_grant_text != "" &&
          responseData != null
        ) {
          this.form.interested_in_grant_text =
            responseData.interested_in_grant_text;
        }

        if (
          responseData.reaching_out_text != "" &&
          responseData.reaching_out_text != null
        ) {
          this.form.reaching_out_text = responseData.reaching_out_text;
        }

        if (
          responseData.external_link != "" &&
          responseData.external_link != null
        ) {
          this.form.external_link = responseData.external_link;
        }

        if (
          responseData.knowledge_academy_thank_you_message != "" &&
          responseData != null
        ) {
          this.form.knowledge_academy_thank_you_message =
            responseData.knowledge_academy_thank_you_message;
        }

        if (responseData.meta_title != "" && responseData.meta_title != null) {
          this.form.meta_title = responseData.meta_title;
        }

        if (
          responseData.meta_description != "" &&
          responseData.meta_description != null
        ) {
          this.form.meta_description = responseData.meta_description;
        }

        if (
          responseData.meta_keywords != "" &&
          responseData.meta_keywords != null
        ) {
          this.form.meta_keywords = responseData.meta_keywords;
        }

        this.edit.knowledge_academy_banner_image_url =
          responseData.knowledge_academy_banner_image;
        this.edit.knowledge_academy_banner_image_mobile_url =
          responseData.knowledge_academy_banner_image_mobile;

        this.edit.thumbnail_image_logo_url = responseData.thumbnail_image_logo;
        this.edit.website_banner_image_url = responseData.website_banner_image;
        this.edit.pre_login_image_url = responseData.pre_login_image;
        this.edit.pre_login_image2_url = responseData.pre_login_image2;
        this.edit.thumbnail_image_url = responseData.thumbnail_image;
        this.edit.invitation_file_url = responseData.invitation_file;
        this.edit.image_name_url = responseData.image_name;

        if (responseData.cta_data) {
          this.cta_data = responseData.cta_data;
        }
        var cta_data = {};
        Object.entries(cta_data).forEach((d) => {
          cta_data[d[0]].active =
            d[1].active == "on" || d[1].active == "true" ? true : false;
        });

        var forum_tabs = {};
        if (responseData.forum_tabs) {
          if (typeof responseData.forum_tabs == "string") {
            forum_tabs = JSON.parse(responseData.forum_tabs);
          } else {
            forum_tabs = responseData.forum_tabs;
          }
        }
        if (responseData.forum_tabs) {
          Object.entries(forum_tabs).forEach((d) => {
            forum_tabs[d[0]].active =
              d[1].active == "on" || d[1].active == true ? true : false;
          });
          if (!Object.keys(forum_tabs).includes("brand_material")) {
            forum_tabs.brand_material = {
              index: 9,
              label: "Brand Material",
              active: false,
            };
          }
          this.forum_tabs = forum_tabs;
        }
        if (
          responseData.forum_contribution.length > 0 &&
          responseData.forum_contribution != null &&
          responseData.forum_contribution != ""
        ) {
          this.form.forum_contribution = [];
          const contributors = responseData.forum_contribution;
          for (let index = 0; index < contributors.length; index++) {
            const member_types = contributors[index].member_type;
            this.form.forum_contribution.push({
              forum_contribution_title:
                contributors[index].forum_contribution_title,
              member_type: member_types,
            });
          }
        }

        if (
          responseData.color_details != "" &&
          responseData.color_details != null
        ) {
          if (
            responseData.color_details.text_color != "" &&
            responseData.color_details.text_color != null
          ) {
            this.form.text_color = responseData.color_details.text_color;
          }
          if (
            responseData.color_details.bg_color != "" &&
            responseData.color_details.bg_color != null
          ) {
            this.form.bg_color = responseData.color_details.bg_color;
          }
          if (
            responseData.color_details.bg_light_color != "" &&
            responseData.color_details.bg_light_color != null
          ) {
            this.form.bg_light_color =
              responseData.color_details.bg_light_color;
          }
        }

        this.oldForm = _.clone(this.form);
      }

      this.$store.commit("loader/updateStatus", false);
    },

    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = forum.statusUpdate + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteForum(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = forum.forumUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreForum(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = forum.restoreForum + "/" + id;
        const data = await this.postRequest(url);
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchAllCommunity() {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url);
      if (data.status) {
        this.form.community = data.response.data;
      }
    },
    async fetchCommunity(query) {
      let url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.community = data.response.data;
      }
    },
    async fetchUserType() {
      const url = userType.fetchUser;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;
        this.user_types = responseData;
      }
    },
    async fetchPartner() {
      const url = partner.fetchAllActivePartner;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response.data;
        this.partner = responseData;
      }
    },
    async fetchExpert(query) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.forum_expert = data.response.data;
      }
    },
    async fetchSingleCouncilExpert(id) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.council_experts.push(data.response);
      }
    },
    async fetchSingleOtherExpert(id) {
      const url = expert.expertUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.other_experts.push(data.response);
      }
    },
    async fetchSubSpeciality() {
      const url = subspeciality.fetchAllSubSpeciality;
      const data = await this.getRequest(url);
      if (data.status) {
        this.forum_subspeciality = data.response.data;
      }
    },
    async fetchAdminUser() {
      const url = adminUser.adminUrl;
      const data = await this.getRequest(url);
      if (data.status) {
        this.discussion_forum_manager = data.response.data;
      }
    },
    async fetchAllCountry() {
      const url = country.fetchAllCountry;
      const data = await this.getRequest(url);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "n/a";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          // window.scrollTo({
          //   top: 0,
          //   behavior: "smooth",
          // });
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        if (
          this.form.forum_visibility == "private" &&
          this.form.privacy_rule[0] == "invitation" &&
          this.invitation_file != null &&
          this.invitation_file_url != "" &&
          this.edit.invitation_file_url == null
        ) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "List of Users is Required when Invitation is checked",
          });
          return false;
        }
        if (
          this.form.forum_contribution.filter(
            (f) => f.forum_contribution_title != ""
          ).length != 0
        ) {
          if (
            this.form.forum_contribution.filter(
              (f) => f.member_type.length == 0
            ).length
          ) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Contributor is required",
            });
            return false;
          }
        }
        this.$store.commit("loader/updateStatus", true);
        let url = forum.forumUrl;
        if (this.$route.name == "edit-forum") {
          url = forum.forumUrl + "/" + this.forum_id;
        }
        let dataAppend = new FormData();
        if (this.image_name) {
          dataAppend.append("image_name", this.image_name);
        }
        if (this.website_banner_image) {
          dataAppend.append("website_banner_image", this.website_banner_image);
        }
        if (this.pre_login_image) {
          dataAppend.append("pre_login_image", this.pre_login_image);
        }
        if (this.pre_login_image2) {
          dataAppend.append("pre_login_image2", this.pre_login_image2);
        }
        if (this.thumbnail_image) {
          dataAppend.append("thumbnail_image", this.thumbnail_image);
        }
        if (this.thumbnail_image_logo) {
          dataAppend.append("thumbnail_image_logo", this.thumbnail_image_logo);
        }
        if (this.knowledge_academy_banner_image) {
          dataAppend.append(
            "knowledge_academy_banner_image",
            this.knowledge_academy_banner_image
          );
        }
        if (this.knowledge_academy_banner_image_mobile) {
          dataAppend.append(
            "knowledge_academy_banner_image_mobile",
            this.knowledge_academy_banner_image_mobile
          );
        }
        if (this.knowledge_academy_banner_image) {
          dataAppend.append(
            "knowledge_academy_banner_image",
            this.knowledge_academy_banner_image
          );
        }
        if (this.knowledge_academy_banner_image_mobile) {
          dataAppend.append(
            "knowledge_academy_banner_image_mobile",
            this.knowledge_academy_banner_image_mobile
          );
        }
        dataAppend.append(
          "forum_contribution",
          JSON.stringify(this.form.forum_contribution)
        );
        dataAppend.append("invitation_file", this.invitation_file);
        for (var key in this.form) {
          if (
            key != "community" &&
            key != "sub_specialities" &&
            key != "user_types" &&
            key != "council_experts" &&
            key != "other_experts" &&
            key != "forum_manager" &&
            key != "country" &&
            key != "state" &&
            key != "city" &&
            key != "is_open_forum" &&
            key != "show_followers" &&
            key != "is_brand" &&
            key != "is_knowledge_academy_active" &&
            key != "invitation_file" &&
            key != "forum_contribution" &&
            key != "is_homepage_custom"
          ) {
            if (key == "privacy_rule") {
              dataAppend.append(key, JSON.stringify(this.form[key]));
            } else {
              dataAppend.append(key, this.form[key]);
            }
          }
        }
        if (this.form.is_homepage_custom) {
          dataAppend.append("is_homepage_custom", 1);
        } else {
          dataAppend.append("is_homepage_custom", 0);
        }
        if (this.form.is_open_forum) {
          dataAppend.append("is_open_forum", 1);
        } else {
          dataAppend.append("is_open_forum", 0);
        }
        if (this.form.show_followers) {
          dataAppend.append("show_followers", 1);
        } else {
          dataAppend.append("show_followers", 0);
        }
        if (this.form.is_brand) {
          dataAppend.append("is_brand", 1);
        } else {
          dataAppend.append("is_brand", 0);
        }
        if (this.form.is_knowledge_academy_active) {
          dataAppend.append("is_knowledge_academy_active", 1);
        } else {
          dataAppend.append("is_knowledge_academy_active", 0);
        }
        let city = [];
        if (this.form.city != "") {
          if (Array.isArray(this.form.city)) {
            this.form.city.map((key) => {
              city.push(key.city);
            });
          } else {
            city.push(this.form.city);
          }
        }

        let state = [];
        if (this.form.state != "") {
          if (Array.isArray(this.form.state)) {
            this.form.state.map((key) => {
              state.push(key.state);
            });
          } else {
            state.push(this.form.state);
          }
        }

        let country = [];
        if (this.form.country != "") {
          if (Array.isArray(this.form.country)) {
            this.form.country.map((key) => {
              country.push(key.name);
            });
          } else {
            country.push(this.form.country);
          }
        }

        const community = [];
        this.form.community.map((key) => {
          community.push(key.id);
        });
        const sub_specialities = [];
        this.form.sub_specialities.map((key) => {
          sub_specialities.push(key.id);
        });
        const user_types = [];
        this.form.user_types.map((key) => {
          user_types.push(key.value);
        });
        const council_experts = [];
        this.form.council_experts.map((key) => {
          council_experts.push(key.id);
        });
        const other_experts = [];
        this.form.other_experts.map((key) => {
          other_experts.push(key.id);
        });
        const forum_manager = [];
        this.form.forum_manager.map((key) => {
          forum_manager.push(key.id);
        });

        dataAppend.append(
          "country",
          country.length ? JSON.stringify(country) : ""
        );
        dataAppend.append(
          "community",
          community.length > 0 ? JSON.stringify(community) : ""
        );
        dataAppend.append(
          "sub_specialities",
          sub_specialities.length > 0 ? JSON.stringify(sub_specialities) : ""
        );
        dataAppend.append(
          "user_types",
          user_types.length > 0 ? JSON.stringify(user_types) : ""
        );
        dataAppend.append(
          "council_experts",
          council_experts.length > 0 ? JSON.stringify(council_experts) : ""
        );
        dataAppend.append(
          "other_experts",
          other_experts.length > 0 ? JSON.stringify(other_experts) : ""
        );
        dataAppend.append(
          "forum_manager",
          forum_manager.length > 0 ? JSON.stringify(forum_manager) : ""
        );
        dataAppend.append("city", city.length > 0 ? JSON.stringify(city) : "");
        dataAppend.append(
          "state",
          state.length > 0 ? JSON.stringify(state) : ""
        );
        if (this.$route.name == "edit-forum") {
          dataAppend.append("cta_data", JSON.stringify(this.cta_data));
          dataAppend.append("forum_tabs", JSON.stringify(this.forum_tabs));
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/forum");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-forum")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-forum")) {
            this.fields[7] = {
              key: "delete",
            };
          } else {
            this.fields[8] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-forum")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[7] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-forum")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[8] = {
              key: "delete",
            };
          }
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    status(v) {
      if (v == 1) {
        this.fetchAllCommunity();
      } else {
        if (this.$route.name == "add-forum") {
          this.form.community = [];
        } else if (this.$route.name == "edit-forum") {
          this.form.community = this.oldForm.community;
        }
      }
    },
    "form.is_open_forum"(v) {
      if (v == 1) {
        this.form.is_open_forum = true;
      } else {
        this.form.is_open_forum = false;
      }
    },
    "form.show_followers"(v) {
      if (v == 1) {
        this.form.show_followers = true;
      } else {
        this.form.show_followers = false;
      }
    },
    "form.is_brand"(v) {
      if (v == 1) {
        this.form.is_brand = true;
      } else {
        this.form.is_brand = false;
      }
    },
    "form.is_knowledge_academy_active"(v) {
      if (v == 1) {
        this.form.is_knowledge_academy_active = true;
      } else {
        this.form.is_knowledge_academy_active = false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "add-forum" || this.$route.name == "edit-forum") {
      this.fetchForumContribution();
      this.fetchUserType();
      this.fetchAdminUser();
      this.fetchSubSpeciality();
      this.fetchAllCountry();
      this.fetchPartner();
      if (this.$route.name == "edit-forum") {
        this.fetchForum(this.$route.params.id);
      }
    } else {
      this.activeTab = "all";
      if (this.$route.query.filter) {
        this.activeTab = this.$route.query.filter;
      }
      if (this.$route.query.search) {
        this.filter = this.$route.query.search;
      }
      if (this.$route.query.page && this.$route.query.page != 1) {
        this.currentPage = parseInt(this.$route.query.page);
      } else {
        this.fetchData(this.activeTab);
      }
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};
